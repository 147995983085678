<template>
  <div class="card mb-xl-8 card">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <span class="card-date"> {{ date }} </span>
        </div>
        <div>
          <el-button v-if="show_user && update_user" class="icon-btn" @click="$emit('update', item)">
            <i class="las la-pen"></i>
          </el-button>
          <el-button
            class="icon-btn"
            v-if="assign_offices"
            @click="$emit('assign_offices', item)"
          >
            <i class="las la-arrow-right"></i>
          </el-button>
          <el-button v-if="delete_user" class="icon-btn" @click="$emit('delete', item.id)">
            <i class="las la-trash"></i>
          </el-button>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <Avatar :avatar="item.avatar" only_view principal_div_class="me-4" />
        <div class="pt-4 bg-white flex-grow-1">
          <div class="row g-0 mt-2">
            <div class="key-value">{{ item.name }}</div>
            <div class="key">
              <a
                style="color: #00b2ff"
                class="cursor-pointer"
                :href="'mailto:' + item.email"
                >{{ item.email }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button v-if="show_user && update_user" class="details-btn" @click="$emit('seeDetail', item.id)">
          {{ $t("btnDetails") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { hasPermission } from "@/core/helpers/functions";
import Avatar from "@/components/avatar/avatar";

export default {
  name: "CardUserNew",
  components: { Avatar },
  props: {
    item: Object,
  },

  setup(props, { emit }) {
    return {
      delete_user: ref(hasPermission("users: delete")),
      update_user: ref(hasPermission("users: update")),
      update_password: ref(hasPermission("users: update password")),
      assign_offices: ref(hasPermission("users: assign offices")),
      show_user: ref(hasPermission("users: show")),
      api_host: process.env.VUE_APP_API_URL,
    };
  },
};
</script>
