
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import CardUserNew from "@/components/cards/CardUserNew.vue";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "ManagementUsers",
  components: {
    OfficeSelect,
    CardUserNew,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const search = ref("");
    const permissions = computed(() => store.getters.permissions);
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "users: create") !== -1
      );
    });
    const canSeeOffices = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: index") !== -1
      );
    });
    const canSeeDetails = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "users: show") !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "users: update") !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "users: delete") !== -1
      );
    });
    const temp = localStorage.getItem("user") as any;
    const user = JSON.parse(temp);
    const dataList = ref([]);
    const filtered = ref([]);
    const queryData = ref([]);
    const viewType = ref(user.user_preferences.view_type_items);
    const originalData = ref([]);
    const showDelete = ref(false);
    const cantDelete = ref(false);
    const deleteId = computed(() => store.getters.DeleteId);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.user_preferences.items_per_page,
      total: 1,
      last_page: 1,
    });

    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("iemail"), key: "email", type: "email" },
    ]);

    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "danger" as never,
      } as never,
    ]);

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          name: element.name,
          email: element.email,
        };
        return element;
      });
    };

    const getItems = async () => {
      ApiService.query(`/api/users`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          name: search.value,
          offices: queryData.value,
        },
      }).then(({ data }) => {
        originalData.value = data.users;
        filtered.value = [];
        dataList.value = createFieldItems(data.users);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        filtered.value =
          viewType.value === "card" ? data.users : createTableItems(data.users);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("usersIndex", [
        { route: "/users", label: "user" },
      ]);
      getItems();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const onSearch = () => {
      let data = createFieldItems(dataList.value);
      if (viewType.value == "table") {
        data = createTableItems(dataList.value);
      }
      filtered.value = data.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      if (!filtered.value.length) {
        getItems();
      }
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          name: element.name,
          email: element.email,
        });
      });
      return result;
    };

    const reload = () => {
      getItems();
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push(`/user/${param1}/settings/`);
      }
      if (param2 === "REMOVE") {
        deleteDialog(param1);
      }
    };

    const createElement = () => {
      router.push("/user/add");
    };

    const deleteDialog = (value) => {
      store.commit("setDeleteId", value);
      showDelete.value = true;
    };

    const deleteElement = () => {
      ApiService.delete(`/api/users/${deleteId.value}`).then(function () {
        store.commit("setDeleteId", undefined);
        showDelete.value = false;
        getItems();
      });
    };

    const addOffice = (data) => {
      queryData.value = data;
    };

    return {
      canCreate,
      canSeeDetails,
      canSeeOffices,
      canEdit,
      canDelete,
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      showDelete,
      cantDelete,
      queryData,
      addOffice,
      onSearch,
      getItems,
      currentPageChange,
      setItemsPerPage,
      reload,
      actionsButton,
      createElement,
      deleteDialog,
      deleteElement,
      user,
    };
  },
});
